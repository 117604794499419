#main-sections {
    mark {
        display: inline-block;
        line-height: 0em;
        padding-bottom: 0.5em;
        background-color: rgba(239, 35, 60, .3);
    }
    section {
        margin-top: 5rem;
    }
    @media (max-width: 640px) {
        margin-top: 1rem;
        section, #landing-buttons {
            margin-top: 2rem;
            margin-left: 2rem;
        }
    }
    @media (max-width: 320px) {
        h3, p, li {
            font-size: smaller;
        }
    }
}

#landing {
    max-width: 60vw;
    
    h1 {
        margin-top: 10rem;
        margin-bottom: 2rem;
        font-size: 6rem;
        letter-spacing: -.3rem;
        span {
            color: #ef233c;
        }
    }
    @media (max-width:640px) {
        h1 {
            margin-top: 2rem;
            font-size: 5rem;
        }
    }
}

#landing-buttons {
    display: flex;
    margin-top: 3rem;
    justify-content: space-between;
    max-width: 18rem;
    @media (max-width: 640px) {
        flex-direction: column;
        max-width: 11rem;
        * {
            margin-bottom: 1rem;
        }
    }
}

@media (min-width: 768px) {
    #about {
        position: fixed;
    }
}

#projects {
    margin-bottom: 3rem;
}


#about, #projects {
    max-width: 60vw;
    height: unset;
    min-height: 100vh;
    .section-link {
        color: #ef233c;
        text-decoration: none;
        font-weight: bold;
        &:hover {
            cursor: pointer;
        }
    }
    .other-components {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        position: fixed;
        right: 6rem;
        svg {
            font-size: 3.2rem;
            margin-bottom: 1rem;
            color: #6c757d;
            transition: ease-in-out .3s;
            &:hover {
                color: #212529;
            }
        }
        @media (max-width: 768px) {
            right: 3rem;
        }
        @media (max-width: 640px) {
            right: 1.2rem;
            top: 3vh;
            svg {
                font-size: 2.5rem;
            }
        }
        @media (max-width: 320px) {
            top: 2vh;
        }
    }
    @media (max-width: 640px) {
        max-width: 70vw;
        h3 {
            font-size: 1.1rem;
        }
    }
    @media (max-width: 320px) {
        h3 {
            font-size: smaller;
        }
    }
}

#about-content {
    display: flex;
    align-items: center;
    margin-top: 9rem;
    img {
        max-height: 14rem;
        margin-right: 2rem;
        margin-top: -24vh;
        border-radius: 50%;
        box-shadow: 18px 17px 0px 0px rgb(239 35 60 / 30%);
    }
    @media (max-width: 1024px) {
        flex-direction: column;
        margin-top: unset;
        img {
            margin-right: unset;
            margin-top: unset;
            position: relative;
            right: -17vw;
            z-index: 1;
        }
        div {
            position: relative;
            top: 5vh;
        }
    }
}