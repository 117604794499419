#sidebar {
    height: 100vh;
    width: 10vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    margin-left: 1rem;
    margin-right: 3rem;
    font-size: 2rem;
    a {
        color: #6c757d;
        transition: ease-in-out .3s;
        &:hover {
            color: #212529;
        }
    }
    svg {
        margin: 1rem 0;
        width: 3rem;
    }
    @media (max-width: 640px) {
        justify-content: flex-end;
        height: 100%;
        align-items: unset;
        position: fixed;
        right: 0;
        margin-left: 2rem;
        width: 5vw;
    }
}