.flip-in-ver-left {
	-webkit-animation: flip-in-ver-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: flip-in-ver-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes flip-in-ver-left {
    0% {
      -webkit-transform: rotateY(80deg);
              transform: rotateY(80deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
      opacity: 1;
    }
  }
  @keyframes flip-in-ver-left {
    0% {
      -webkit-transform: rotateY(80deg);
              transform: rotateY(80deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
      opacity: 1;
    }
  }
  
  .flip-in-ver-right {
	-webkit-animation: flip-in-ver-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: flip-in-ver-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


 @-webkit-keyframes flip-in-ver-right {
    0% {
      -webkit-transform: rotateY(-80deg);
              transform: rotateY(-80deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
      opacity: 1;
    }
  }
  @keyframes flip-in-ver-right {
    0% {
      -webkit-transform: rotateY(-80deg);
              transform: rotateY(-80deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
      opacity: 1;
    }
  }
  
.button {
  &.about {
    --color-hover: var(--color);
    --background: #2B3044;
    --hover-back: #c7071e;
    --hover-front: #ef233c;

  }
  &.back {
      --background: #275EFE;
      --background-hover: #1749DB;
  }
}

.button {
    --color: #fff;
    --color-hover: var(--color);
    --background: #2B3044;
    --background-hover: var(--background);
    --hover-back: #6D58FF;
    --hover-front: #5C86FF;
    padding: 8px 28px;
    border-radius: 20px;
    line-height: 24px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: .02em;
    border: none;
    outline: none;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    color: var(--c, var(--color));
    background: var(--b, var(--background));
    transition: color .2s linear var(--c-d, .2s), background .3s linear var(--b-d, .2s);
    &:not(.simple) {
        &:before,
        &:after {
            content: '';
            position: absolute;
            background: var(--pb, var(--hover-back));
            top: 0;
            left: 0;
            right: 0;
            height: 200%;
            border-radius: var(--br, 40%);
            transform: translateY(var(--y, 50%));
            transition: transform var(--d, .4s) ease-in var(--d-d, 0s), border-radius .5s ease var(--br-d, .08s);

        }
        &:after {
            --pb: var(--hover-front);
            --d: .44s;
        }
    }
    div {
        z-index: 1;
        position: relative;
        display: flex;
        span {
            display: block;
            backface-visibility: hidden;
            transform: translateZ(0);
            animation: var(--name, none) .7s linear forwards .18s;
        }
    }
    &.in {
        --name: move;
        &:not(.out) {
            --c: var(--color-hover);
            --b: var(--background-hover);
            &:before,
            &:after {
                --y: 0;
                --br: 5%;
            }
            &:after {
                --br: 10%;
                --d-d: .02s;
            }
        }
        &.out {
            --name: move-out;
            &:before {
                --d-d: .06s;
            }
        }
    }
}

@keyframes move {
    30%,
    36% {
        transform: translateY(calc(-6px * var(--move))) translateZ(0) rotate(calc(-13deg * var(--rotate) * var(--part)));
    }
    50% {
        transform: translateY(calc(3px * var(--move))) translateZ(0) rotate(calc(6deg * var(--rotate) * var(--part)));
    }
    70% {
        transform: translateY(calc(-2px * var(--move))) translateZ(0) rotate(calc(-3deg * var(--rotate) * var(--part)));
    }
}

@keyframes move-out {
    30%,
    36% {
        transform: translateY(calc(6px * var(--move))) translateZ(0) rotate(calc(13deg * var(--rotate) * var(--part)));
    }
    50% {
        transform: translateY(calc(-3px * var(--move))) translateZ(0) rotate(calc(-6deg * var(--rotate) * var(--part)));
    }
    70% {
        transform: translateY(calc(2px * var(--move))) translateZ(0) rotate(calc(3deg * var(--rotate) * var(--part)));
    }
}

html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}