@import url('https://fonts.googleapis.com/css2?family=Francois+One&family=Open+Sans&display=swap');
@import "./style/layout/Sidebar.scss";
@import "./style/layout/Main.scss";
@import "./style/Projects.scss";
@import "./style/Animations.scss";

#content {
    display: flex;
    height: 100%;
    @media (max-width: 640px) {
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
}