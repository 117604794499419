#projects {
    ul {
        margin-left: -2.5rem;
        list-style: none;
        li {
            margin-bottom: .5rem;
        }
        svg {
            margin-right: 1rem;
            color: #6c757d;
        }
    }
}

#project-cards {
    display: flex;
    margin-bottom: 2rem;
    .project-card {
        width: 9rem;
        height: 9rem;
        padding: 2rem;
        margin: 1rem 1.5rem 1.5rem 0;
        background-color: #f3f3f3;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
        img {
            width: 4rem;
            height: 4rem;
        }
        &:hover {
            cursor: pointer;
        }
        @media (max-width: 640px) {
            width: 5rem;
            height: 5rem;
            img {
                width: 2rem;
                height: 2rem;
            }
        }
        @media (max-width: 320px) {
            width: 3rem;
            height: 3rem;
            img {
                width: 1rem;
                height: 1rem;
            }
        }
    }
}

.project-overlay {
  position: fixed; 
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100vw; 
  height: 100vh; 
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4); 
  .project-modal {
    background-color: #fefefe;
    margin: 5% auto; 
    padding: 20px;
    border: 1px solid #888;
    width: 80%; 
    a {
        text-decoration: none;
        color: #ef233c;
        transition: ease-in-out .4s;
        &:hover {
            opacity: .5;
        }
    }
    .modal-screen {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    img {
        max-width: 80%;
        margin: 1.5rem auto;
    }
    .close {
        color: #aaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
        &:hover {
            color: black;
            text-decoration: none;
            cursor: pointer;
        }
    }
  }
}